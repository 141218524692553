<template lang="pug">
#add-to-homescreen
  template(v-if="showRotateInstructions")
    #please-rotate
      icon(data="@icon/phone-rotate-portrait.svg")
      span Please turn your phone so the screen is taller than it is wide.

  template(v-else)
    #aths-title(:class="{ 'no-steps': platform.notMobile || !isSupported }")
      #logo
        icon(data="@icon/kit-logo.svg", color="#3F3F3F #151515")
      h1 {{ title }}

    template(v-if="platform.notMobile")
      p The Kit web app is currently only supported on mobile devices.
      p Please open this app in Safari on iOS or Chrome on Android to continue.

    template(v-if="accessAllowed")
      .instructions
        template(v-if="platform.isIPhone")
          .step
            .step-num 1
            .step-text Tap the <span class="aths-menu-item menu-item">#[icon(data="@icon/ios_share.svg", color="#61A6FC")]</span> icon below
          .step
            .step-num 2
            .step-text Select <span class="aths-menu-item"> #[ Add to Home Screen #[icon(data="@icon/ios-aths.svg", :fill="false")]]</span> from the menu that pops up. #[strong You may need to scroll down to find this menu item.]

        template(v-if="platform.isAndroid")
          .step
            .step-num 1
            .step-text Tap the <span class="aths-menu-item menu-item">#[icon(data="@icon/more_vert.svg", color="black")]</span> icon in the browser bar
          .step
            .step-num 2
            .step-text Tap <span class="aths-menu-item menu-item">#[icon(data="@icon/add_to_home_screen_chrome.svg", class="android-add-to-homescreen", color="black")]  Add to Home screen</span>
        .step
          .step-num 3
          .step-text Open the <span id="open-app">#[icon(data="@icon/kit-logo.svg", color="#3F3F3F #151515")]</span> app

      icon#ios-animated-arrow(
        v-if="isSupported && platform.isIPhone",
        data="@icon/arrow_downward.svg"
      )
      icon#android-animated-arrow(
        v-if="isSupported && platform.isAndroid",
        data="@icon/arrow_downward.svg"
      )

    template(v-if="!platform.notMobile && (!isSupported || !platform.isAllowedBrowser)")
      p The Kit web app is currently only supported by the latest version of {{ supportedBrowser }}.
      p Please open this link in {{ supportedBrowser }} to continue.

      button(@click="copyURLToClipboard") Copy link to clipboard
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'
const { isSupported } = require('twilio-video')
const UAParser = require('ua-parser-js')
export default {
  props: {},

  data() {
    return {
      platform: {},
      isSupported,
    }
  },

  created() {
    if (this.$route.query.q) {
      this.$amplitude.getInstance().setUserId(this.$route.query.q)
    } else if (this.$route.query.barcode) {
      this.$amplitude.getInstance().setUserId(this.$route.query.barcode)
    } else if (!this.$route.query.id) {
      this.$amplitude.getInstance().setUserId(this.$amplitude.getInstance()._sessionId)
      this.$router.push({query: {id: this.$amplitude.getInstance()._sessionId}})
      window.location.reload();
    }
    this.parsePlatformData()
    this.appHeight()
    if (this.accessAllowed && !localStorage.getItem('accessedHomescreen')) {
      AmplitudeAPI.logEvent('AddToHomescreen', {
        source: this.$route.query.src,
        kit_type: this.$route.query.type
      })
      localStorage.setItem('accessedHomescreen', true)
    }
    if (this.unsupportedBrowser) {
      AmplitudeAPI.logEvent('UnsupportedBrowser', {
        source: this.$route.query.src,
        kit_type: this.$route.query.type
      })
    }
    if (this.platform.notMobile) {
      AmplitudeAPI.logEvent('NonMobileAccess', {
        source: this.$route.query.src,
        kit_type: this.$route.query.type
      })
    }
  },

  computed: {
    showRotateInstructions,
    supportedBrowser,
    title,
    accessAllowed,
    unsupportedBrowser,
  },

  watch: {
    '$breakpoint.orientation': {
      handler: async (newVal, oldVal) => {
        // scroll to make the browser nav bars appear in iOS safari after rotation from landscape to portrait
        if (newVal == 'portrait') {
          setTimeout(() => {
            window.scroll({ top: 1, left: 0, behavior: 'smooth' })
          }, 500)
        }
      },
    },
  },

  methods: {
    copyURLToClipboard,
    parsePlatformData,
    appHeight,
  },

  components: {},
}
/* Computed ---------------------------------------------------- */
function unsupportedBrowser() {
  return !this.platform.notMobile && (!this.isSupported || !this.platform.isAllowedBrowser)
}

function accessAllowed() {
  return !this.platform.notMobile && this.isSupported && this.platform.isAllowedBrowser
}

function showRotateInstructions() {
  return !this.platform.notMobile && this.$breakpoint.orientation == 'landscape'
}

function supportedBrowser() {
  return this.platform.isIPhone ? 'Safari' : 'Chrome'
}

function title() {
  return this.platform.notMobile
    ? 'Please open this site on a mobile device'
    : this.isSupported
    ? 'Install the Kit app to continue'
    : 'Unsupported Browser'
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function copyURLToClipboard() {
  navigator.clipboard.writeText(document.URL)
}

function parsePlatformData() {
  const userAgent = new UAParser()
  const isIPhone = /iphone/i.test(userAgent.getDevice().model)
  const isAndroid = /android/i.test(userAgent.getOS().name)
  const browserName = userAgent.getBrowser().name

  this.platform = {
    isIPhone: isIPhone,
    isAndroid: isAndroid,
    notMobile: !(isIPhone || isAndroid),
    isAllowedBrowser:
      ((isIPhone && browserName.includes('Safari')) || (isAndroid && browserName == 'Chrome')) &&
      ('onbeforeinstallprompt' in window || isIPhone),
  }
}

function appHeight() {
  const myObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    })
  })
  myObserver.observe(document.body)
}
</script>
